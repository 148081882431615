.goals{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    padding: 30px 0px;
}
.goals-container{
    width: 94%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.goals-content{
    width: 420px;
    display: flex;
    flex-direction: column;
}
.goals-header{
    margin: 6px 0px;
}
.goals-header p{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}

@media screen and (max-width: 1025px) {
    .goals-image{
        width: 400px;
    }
}

@media screen and (max-width: 890px) {
    .goals-image{
        width: 380px;
    }
    .goals-content{
        width: 40%;
    }
}

@media screen and (max-width: 800px) {
    .goals-container{
        flex-direction: column;
    }
    .goals-content{
        padding: 40px 0px;
        width: 80%;
    }
    .goals-image{
        width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .goals-image{
        width: 370px;
    }
}
@media screen and (max-width: 430px) {
    .goals-image{
        width: 300px;
    }
    .goals-content{
        width: 95%;
    }

}
@media screen and (max-width: 300px) {
    .goals-image{
        width: 290px;
    }
}