.services-block-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-position: center;
    background-size: cover;
}
.services-block-header{
    width: 30%;
}
.services-block-header p {
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}
.services-block{
    width: 53%;
    display: flex;
    justify-content: space-around;
}
.first-block-div{
    /* height: 570px; */
    width: 365px;
    margin-top: 57px;       
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.second-block-div{
    /* height: 570px; */
    width: 365px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.div-block{
    width: 99%;
    background-color: rgb(249, 249, 249);
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 20px 0px;
    transition: 1s;

}
.div-block:hover{
    cursor: pointer;
    border: 2px solid #98c03b;
}
.block-image{
    width: 135px;
}
@media screen and (max-width: 1345px) {
    .services-block{
        width: 60%;
    }
    
}
@media screen and (max-width: 1210px) {
    .services-block-container{
        flex-direction: column;
    }
    .services-block{
        margin: 20px 0px;
        width: 85%;
    }
    .services-block-header{
        margin: 20px 0px;
        width: 60%;
    }
}
@media screen and (max-width: 860px) {
    .services-block{
        width: 95%;
    }
}
@media screen and (max-width: 800px) {
    .services-block{
        /* border: 1px solid blue; */
        flex-direction: column;
        align-items: center;
    }
    .first-block-div{
        margin-top: -1%;
        width: 70%;
    }
    .second-block-div{
        margin-top: 20px;
        width: 70%;
    }
    .services-block-header{
        width: 75%;
    }
    .block-image{
        width: 200px;
    }
    
}
@media screen and (max-width: 580px) {
    .first-block-div{
        width: 85%;
    }
    .second-block-div{
        width: 85%;
    }

}
@media screen and (max-width: 500px) {
    .block-image{
        width: 150px;
    }

    .div-block{
        height: 420px;
    }
    /* .div-block p{
        padding: 0px 5px;
    }
    .first-block-div{
        height: 600px;
    }
    .second-block-div{
        height: 600px;
    } */

}
/* @media screen and (max-width: 400px) {
    .div-block{
        width: 78%;
        height: 295px;
    }
    .first-block-div{
        height: 615px;
    }
    .second-block-div{
        height: 615px;
    }

} */
@media screen and (max-width: 340px) {
    .first-block-div{
        width: 95%;
    }
    .second-block-div{
        width: 95%;
    }

}