.wwa{
    width: 100%;
    /* height: 500px; */
    background-color: #214d6f;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
    padding: 30px 0px;
}
.wwa-container{
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.wwa-content{
    width: 420px;
}
.wwa-header{
    margin: 6px 0px;
}
.wwa-header p {
    color: #98c03b;
}
.wwa-header h1{
    color: #98c03b;
}
.wwa-desc p{
    color: rgb(230, 230, 230);
    margin: 6px 0px;
}
.wwa-content a{
    text-decoration: none;
}
.wwa-button{
    background-color: #98c03b;
    color: white;
    width: 100px;
    height: 35px;
    cursor: pointer;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.3s;
}
.wwa-button:hover{
    background-color: #677f2f;
}

@media screen and (max-width: 1025px) {
    .wwa-image{
        width: 400px;
    }
    .wwa-content{
        width: 47%;
    }
}
@media screen and (max-width: 890px) {
    .wwa-image{
        width: 350px;
    }
    .wwa-content{
        width: 49%;
    }

}
@media screen and (max-width: 800px) {
    .wwa-image{
        padding: 30px 0px;
        width: 400px;
    }
    .wwa-container{
        flex-direction: column;
    }
    .wwa-content{
        padding: 30px 0px;
        width: 80%;
    }
}
@media screen and (max-width: 470px) {
    .wwa-image{
        width: 350px;
    }
    .wwa-content{
        width: 95%;
    }
}
@media screen and (max-width: 430px) {
    .wwa-image{
        width: 300px;
    }
}
@media screen and (max-width: 300px) {
    .wwa-image{
        width: 290px;
    }
}