.navbar{
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    position: fixed;
    background-color: white;
    transition: 0.3s;
}
.navbar-bx{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}
.navbar-container{
    display: flex;
    width: 94%;
    justify-content: space-between;
}
.logo-image{
    width: 200px;
}
.nav-links{
    width: 45%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.nav-links a {
    color: rgb(90, 90, 90);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
}
.nav-links a:hover{
    color: #98c03b;
    transform: scale(1.1);
}
.nav-link-item{
    padding: 5px 5px;
}
.nav-links .active {
    color: #98c03b;
    border-bottom: 2px solid #98c03b;
}
.nav-links .active:hover {
    transform: none;
}
.menu-icon{
    display: none;
    color: #214d6f;
    cursor: pointer;
}
.mobile-menu{
    width: 100%;
    transition: 0.3s all;
}
.mobile-menu-links{
    display: flex;
    height: 180px;
    justify-content: space-between;
    width: 50%;
    flex-direction: column;
    align-items: flex-end;
}
.mobile-nav-link-item{
    color: rgb(221, 221, 221);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
}
.open{
    height: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #214d6f;
    padding-right: 5%;
}

.close{
    height: 0px;
}
.close .mobile-nav-link-item{
    display: none;
}
@media screen and (max-width: 1010px) {
    .nav-links{
        width: 50%;
    }

}
@media screen and (max-width: 960px) {
    .menu-icon{
        display: flex;
    }
    .nav-link-item{
        display: none;
    }
    .nav-links{
        width: 5%;
    }
    
}
@media screen and (max-width: 450px) {
    .navbar{
        margin-top: .3px;
        
    }
    .logo-image{
        width: 130px;
        padding-top: 10px;
    }
}