.services-banner{
    width: 94%;
    margin: 5% 3%;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}
.services-banner h1{
    color: rgb(221, 221, 221);
    width: 70%;
    text-align: center;
}
button:hover{
    background-color: #677f2f;
}
@media screen and (max-width: 800px) {
    .services-banner h1{
        width: 90%;
    }    
}
