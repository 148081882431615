 .home-erp-lists{
    padding: 10px 0px;
}
.home-erp-header p{
    color: #98c03b;
    font-weight: 600;
}
.home-erp-desc{
    padding: 10px 0px;
}
.list{
    font-weight: 600;
    padding: 8px 0px;
} 
.home-erp-image{
    width: 370px
}
.home-erp{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0px;
    margin-top: 6%;
    overflow: hidden;
}
.home-erp-content{
    width: 40%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
@media screen and (max-width: 1200px) {
    .home-erp-content{
        width: 400px;
    }
    .home-erp-desc{
        padding: 10px 0px;
    }
    .home-erp-image{
        width: 280px;
    }
}
@media screen and (max-width: 800px) {
    .home-erp-image{
        width: 320px;
    }
    .home-erp{
        flex-direction: column;
        align-items: center;
    }
    .home-erp-content{
        display: flex;
        width: 75%;
        padding: 20px 0px;
    }
    .home-erp-desc{
        padding: 10px 0px;
    }
    .home-erp-header{
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 600px) {
    .home-erp-image{
        width: 280px;
    }
    .home-erp-content{
        width: 80%;
    }

}
@media screen and (max-width: 450px) {
    .home-erp-image{
        width: 230px;
    }
}