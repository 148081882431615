.testimonials{
    width: 100;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
}
.testimonials-container{
    width: 94%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.testimonials-content{
    display: flex;
    flex-direction: column;
}
.testimonial-block{
    width: 550px;
    background-color: #f7ffe3;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
}
.testimonial-block h4{
    margin: 15px 0px;
}
.testimonial-block .quote-icon{
    margin: 15px 0px;
}
.testimonials-header{
    width: 400px;
}
.testimonials-header a{
    text-decoration: none;
}
.testimonials-header p{
    margin: 15px 0px;
}
.testimonials-header .tag{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
    margin: 0px 0px;
}
.testimonials-button{
    margin: 15px 0px;
    background-color: #98c03b;
    color: white;
    width: 100px;
    height: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.3s;
}
.testimonials-button:hover{
    background-color: #677f2f;
}
@media screen and (max-width: 1200px) {
    .testimonials-header{
        width: 35%;
    }
    .testimonial-block{
        width: 400px;
    }
}
@media screen and (max-width: 850px) {
    .testimonials-header{
        width: 38%;
    }
    .testimonial-block{
        width: 375px;
    }
    .testimonial-block p{
        padding-right: 40px;
    }
}
@media screen and (max-width: 800px) {
    .testimonials-container{
        flex-direction: column-reverse;
        align-items: center;
    }
    .testimonials-header{
        width: 70%;
    }
    .testimonials-content{
        width: 70%;
    }
    .testimonial-block{
        width: 100%;
    }

}
@media screen and (max-width: 550px) {
    .testimonials-header{
        width: 85%;
    }
    .testimonials-content{
        width: 85%;
    }

}
@media screen and (max-width: 450px) {
    .testimonials-header{
        width: 90%;
    }
    .testimonials-content{
        width: 90%;
    }

}