.erp{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}
.erp-header{
    margin: 10px 0px;
}
.erp-header p{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}
.erp-image{
    width: 370px
}
.erp-content{
    width: 43%;
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 1024px){
    .erp-image{
        width: 400px;
    }
}
@media screen and (max-width: 850px){
    .erp-image{
        width: 380px;
    }
}
@media screen and (max-width: 800px){
    .erp{
        flex-direction: column;
    }
    .erp-image{
        width: 450px;
    }
    .erp-content{
        width: 90%;
    }
}
@media screen and (max-width: 500px){
    .erp-image{
        width: 420px;
    }
}
@media screen and (max-width: 450px){
    .erp-image{
        width: 90%;
    }
}
