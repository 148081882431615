.whatsapp{
    display: flex;
    /* margin-left: 80%; */
    align-items: center;
    position: fixed;
    width: 100%;
    top: 90%;
    justify-content: flex-end;
    z-index: 100;
}
.whatsapp p{
    font-size: 14px;
    padding: 0px 5px;
    background-color: rgb(231, 253, 246);
}
.whatsapp a{
    text-decoration: none;
}
.whatsapp-icon-container{
    background-color: rgb(45, 183, 66);
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 200rem;
    box-sizing: border-box;
    margin: 0px 10px;
}