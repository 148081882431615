.hero{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 93px;
    padding: 45px 0px;
}
.hero-content{
    width: 40%;
}
.hero-content a{
    text-decoration: none;
}
.hero-image{
    width: 40%;
    height: 500px;
    display: flex;
    align-items: center;
}
.h-image-edited{
    width: 450px;
}
.h-image{
    width: 493px;
}
.hero-title{
    font-weight: 700;
    margin: 10px 0px;
}
.hero-desc{
    color: rgb(90, 90, 90);
    margin: 10px 0px;
    line-height: 31px;
}
.no-display{
    display: none;
}
button{
    background-color: #98c03b;
    width: 100px;
    height: 35px;
    cursor: pointer;
    margin: 10px 0px;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    border: none;
}
.hero-button:hover{
    background-color: #677f2f;
}
@media screen and (max-width: 1199px) {
    .h-image{
        width: 450px;
    }
}
@media screen and (max-width: 1095px) {
    .h-image{
        width: 400px;
    }
}
@media screen and (max-width: 975px) {
    .h-image{
        width: 350px;
    }
}
@media screen and (max-width: 800px) {
    .hero{
        flex-direction: column;
    }
    .hero-content{
        width: 90%;
    }    
    .h-image{
        width: 480px;
    }
    .hero-image{
        width: 80%;
        justify-content: center;
    }

}
@media screen and (max-width: 550px) {
    .hero-image{
        height: 450px;
    } 
    .h-image{
        width: 450px;
    }
    .h-image-edited{
        width: 407px;
    }    
}
@media screen and (max-width: 450px) {
    .hero{
        margin-top: 59px;
        padding: 1px 0px;
    }
}
@media screen and (max-width: 400px) {
    .h-image{
        width: 480px;
    }
    .hero-image{
        width: 90%;
        height: 380px;
    } 
    .h-image-edited{
        width: 339px;
    }    
}
