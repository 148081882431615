.core-values{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5% 0%;
}
.core-values-block{
    width: 94%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}
.value{
    width: 300px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
}
.value-image{
    width: 180px;
    margin: 15px 0px;
}