.footer{
    background-color: #214d6f;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-container{
    display: flex;
    padding: 10px 0px;
    width: 94%;
    justify-content: space-between;
    align-items: center;
}
.copyright p{
    color: white;
}
.footer-icons{
    color: white;
    display: flex;
    justify-content: space-around;
    width: 100px;
}
@media screen and (max-width: 413px) {
    .footer-container{
        flex-direction: column;
    }    
    .copyright{
        text-align: center;
        padding: 5px 0px;
    }
    .footer-icons{
        padding: 5px 0px 10px 0px;
    }
}
/* @media screen and (max-width: 380px) {
    .copyright{
        text-align: center;
        padding: 5px 0px;
    }
    .footer-icons{
        padding: 5px 0px 10px 0px;
    }
} */