.home-services{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}
.home-services-header{
    width: 40%;
    text-align: center;
    padding: 20px 0px;
}
.home-services-header p{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}
.home-services-header h1{
    font-weight: 700;
    margin-top: 8px;
}
.home-services-content{
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px;

}
.block{
    width: 27%;
    border: 1px solid rgb(186, 186, 186);
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    transition: 0.3s;
}
.block:hover{
    cursor: pointer;
    /* transform: scale(1.1); */
    border: 2px solid #98c03b;
}
.block-image{
    width: 180px;
}
.block p{
    padding: 0px 7px;
}
@media screen and (max-width: 800px) {
    .home-services-header{
        width: 50%;
    }
    .home-services-content{
        flex-direction: column;
    }
    .block{
        width: 400px;
        margin: 15px 0px;
    }
}
@media screen and (max-width: 683px) {
    .home-services-header{
        width: 70%;
    }
}

@media screen and (max-width: 550px) {
    .block{
        width: 70%;
    }
}
@media screen and (max-width: 450px) {
    .block{
        width: 87%;
    }
}
@media screen and (max-width: 385px) {
    .block{
        width: 95%;
        height: 420px;
    }
}
@media screen and (max-width: 328px) {
    .block{
        width: 97%;
        height: 450px;
    }
}

@media screen and (max-width: 367px) {
    .home-services-header{
        width: 85%;
    }
}
