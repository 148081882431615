.clients{
    width: 100%;
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    overflow: hidden;
}
.clients-container{
    overflow: hidden;
}
.clients-header{
    text-align: center;
    padding: 15px 0px;
    overflow: hidden;
}
.clients-header p{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}
@media screen and (max-width: 900px){
    .client-image{
        padding: 0px 30px;
    }
}
@media screen and (max-width: 800px){
    .clients-container{
        margin-top: -10%;
    }
    .clients{
        flex-direction: column;
    }
    .client-image{
        padding: 20px 0px;
    }
}