/* .aboutUs-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  padding: 45px 0px;
  margin-bottom: 5rem;
}
.About {
  margin-top: 4rem;
}

.ourVision {
  width: 40%;
  padding: 2rem 1.5rem 0 0.5rem;
}
.ourVision h1 {
  padding: 2rem 1.5rem 0 0.5rem;
  font-weight: 500;
}
.About img {
  width: 150px;
  height: 150px;
}
.about-us-body {
  width: 40%;
}


@media screen and (max-width: 1024px) {
  .aboutUs-container {
    width: 95%;
  }
}


@media screen and (max-width: 600px) {
  .aboutUs-container {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem 1rem 1rem;
  }

  .ourVision {
    width: 95%;
    padding: 2rem 1.5rem 0 0.5rem;
  }
  .ourVision h1 {
    padding: 2rem 1.5rem 0 0.5rem;
    font-weight: 500;
  }
  .aboutUs {
    width: 95%;
    margin-top: 3rem;
  }
  .About img {
    display: none;
  }
} */
.about-us{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about-us-body{
  width: 40%;
}
.about-us-body h1{
  margin: 15px 0px;
}
.about-us-image{
  width: 200px;
}
.about-us-blocks{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.about-us-block{
  background-color: #f7ffe3;
  padding: 20px;
  margin: 10px 0px;
}
@media screen and (max-width: 800px) {
  .about-us{
    flex-direction: column;
  }
  .about-us-body{
    width: 90%;
  }
  .about-us-blocks{
    width: 90%;
  }
}