.banner{
    width: 100%;
    height: 40px;
    background-color: #214d6f;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
}
.phone-numbers{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 130px;
    color: rgb(230, 230, 230);
}
.phone-numbers p{
    font-size: 12px;
    color: rgb(230, 230, 230);
}
.email{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 270px;
    color: rgb(230, 230, 230);
}
.email p{
    font-size: 13px;  
    color: rgb(230, 230, 230);
}
@media screen and (max-width: 450px) {
    .banner{
        display: none;
    }
}