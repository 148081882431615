.form{
    width: 100%;
    background-color: rgb(234, 234, 234);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
    flex-wrap: wrap;
    margin: 5% 0%;
}
form{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 40%;
}
input{
    border: 1px solid rgb(206, 206, 206);
    border-radius: 3px;
    height: 40px;
    padding-left: 10px;
    color: rgb(73, 73, 73);
    margin: 10px 0px;
    outline: none;
}
label{
    margin: 5px 0px;
    font-weight: 500;
}
textarea{
    height: 100px;
    padding: 10px 0px 0px 10px;
    outline: none;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 3px;
    color: rgb(73, 73, 73);

}
select {
    border: 1px solid rgb(206, 206, 206);
    border-radius: 3px;
    height: 40px;
    padding-left: 10px;
    color: rgb(73, 73, 73);
    margin: 10px 0px;
    outline: none;
};
.form-btn{
    margin: 20px 0px;
}
.contact-info{
    width: 45%;
}
.contact-info h1{
    margin: 5px 0px;
}
.icons-container{
    margin: 10px 0px;
}
.icons-container p{
    font-weight: 500;
}
.icon{
    color: #98c03b;
    font-size: 20px;
}
@media screen and (max-width: 800px) {
    .form{
        flex-direction: column;
    }
    form{
        width: 90%;
    }
    .contact-info{
        margin: 10px 0px;
        width: 90%;
    }
}