.renewable-energy{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
}
.renewable-energy-content{
    width: 43%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}
.renewable-energy-header p{
    font-size: 12px;
    font-weight: 600;
    color: #98c03b;
}
.renewable-energy-image{
    width: 35%;
}
@media screen and (max-width: 800px){
    .renewable-energy{
        flex-direction: column-reverse;
        margin-top: 13%;
    }
    .renewable-energy-content{
        width: 90%;
    }    
    .renewable-energy-image{
        width: 400px;
    }
}
@media screen and (max-width: 500px){
    .renewable-energy-image{
        width: 360px;
    }
}
@media screen and (max-width: 450px){
    .renewable-energy-image{
        width: 320px;
    }
}
