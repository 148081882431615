*{
  margin: 0;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  transition: o.3s;
}
p{
  color: rgb(117, 117, 117);
  font-size: 16px;
  line-height: 31px;
}
h4{
  color: #214d6f;
}
h1{
  color: #214d6f;
}

@media screen and (max-width: 600px) {
  h1{
    font-size: 24px;
  }
} 